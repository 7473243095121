function playVideo() {
  videoPlayer.play();
  playMP4Video.classList.add('d-none');
  pauseMP4Video.classList.remove('d-none');
}

function pauseVideo() {
  videoPlayer.pause();
  playMP4Video.classList.remove('d-none');
  pauseMP4Video.classList.add('d-none');
}

function muteVideo() {
  const mutedState = videoPlayer.muted;

  if (mutedState) {
    videoPlayer.muted = false;
    muteMP4Video.classList.remove('muted')
    muteMP4Video.setAttribute('aria-label', 'Mute video');
  } else {
    videoPlayer.muted = true;
    muteMP4Video.classList.add('muted')
    muteMP4Video.setAttribute('aria-label', 'Unmute video');
  }
}

const videoPlayer = document.querySelector('#bannerVideoMP4');
const videoControls = document.querySelector('.comp-banner .video-controls');

if (videoControls) {
  const playMP4Video = videoControls.querySelector('#playMP4Video');
  const pauseMP4Video = videoControls.querySelector('#pauseMP4Video');
  const muteMP4Video = videoControls.querySelector('#muteMP4Video');

  playMP4Video.addEventListener('click', playVideo);
  pauseMP4Video.addEventListener('click', pauseVideo);
  muteMP4Video.addEventListener('click', muteVideo);
}
