function getCurrentSelection() {
  const currentSelection = diseaseAndVaccine.options[diseaseAndVaccine.selectedIndex].value;
  return currentSelection;
}

function handleLearnAboutDiseaseSelection(e) {
  e.preventDefault();
  const selection = getCurrentSelection();

  if (selection !== '') {
    diseaseAndVaccineBtn.focus();
  }
}

function handlediseaseAndVaccineBtn(e) {
  e.preventDefault();
  const selection = getCurrentSelection();

  if (selection !== '') {
    window.location.href = selection;
  } else {
    errorMessage.classList.remove('d-none');
  }
}

const diseaseAndVaccine = document.querySelector('#diseaseAndVaccine');
const diseaseAndVaccineBtn = document.querySelector('#diseaseAndVaccineBtn');
const errorMessage = document.querySelector('.form-disease-vaccine + .error-message');

diseaseAndVaccine ? diseaseAndVaccine.addEventListener('change', handleLearnAboutDiseaseSelection) : '';
diseaseAndVaccineBtn ? diseaseAndVaccineBtn.addEventListener('click', handlediseaseAndVaccineBtn) : '';
