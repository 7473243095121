function initNewsletterForm() {
	
	const emailField = document.querySelector('#email');
	const submitButton = document.querySelector('#newsletter-button');
	const subm = document.querySelector('#subm').value
	const subp = document.querySelector('#subp').value
	
	// Validate email field as user types
	emailField.addEventListener('input', function() {
		
		// Clear error state before checking
		emailField.setCustomValidity('');
		emailField.checkValidity();

		if (emailField.validity.typeMismatch || emailField.validity.badInput || emailField.validity.tooLong || emailField.validity.tooShort || emailField.validity.patternMismatch) {
			emailField.setCustomValidity('This does not appear to be a valid email address. Please check your entry and try again.');
		} else if (emailField.validity.valueMissing) {
			emailField.setCustomValidity('Email Address Is Required');
		} else if (emailField.validity.valid) {
			emailField.setCustomValidity('');
		} else {
			emailField.setCustomValidity('Please refresh the page and try again.');
		}
		
	}, false);

	submitButton.addEventListener('click', function(e){
		
		e.preventDefault();
		
		// Validate email address during entry
		emailField.checkValidity();
		
		if (emailField.validity.valid) {
			
			// Email is valid, submit to Constant Contact
			const emailAddr = emailField.value;
				
			// Scrub the input a bit
			const scrubbedEmail = emailAddr.trim().replace(/\s+/g, '');
			
			window.open('https://ui.constantcontact.com/d.jsp?ea=' + scrubbedEmail + '&m=' + subm + '&p=' + subp);
			
		} else {
			// Display error text
			emailField.reportValidity();
		}
	}, false);
}

// Check if the newsletter form is on the page
const newsletterForm = document.querySelector('#newsletter-form');
newsletterForm ? initNewsletterForm() : '';
	