document.addEventListener('DOMContentLoaded', () => {

  function getCitationById(jsonObject, id) { 
    return jsonObject.filter(function (jsonObject) { 
      return (jsonObject['id'] == id); 
    })[0]; 
  }

  const citations = [];
  const cites = document.querySelectorAll('cite');

  cites.forEach(
    function (cite) {
      let citation = {
        'id': cite.dataset.citationid
      };

      citations.push(citation);
    }
  );

  if (0 < citations.length) {
    let path = '/api/citations/';
    let request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (request.readyState == 4) {
        //the request is completed, now check its status
        if (request.status == 200) {
          let json = JSON.parse(request.responseText);

          if (json.citations) {
            let references = document.getElementById('citations');
            references.insertAdjacentHTML('beforebegin', '<h3>References:</h3>');
            let count = 0;

            cites.forEach(
              function (cite) {
                count++;
                let id = cite.dataset.citationid;
                let citation = getCitationById(json.citations, id);
                let innerHtml = cite.innerHTML.trim();

                if ('&nbsp;' == innerHtml) innerHtml = ''; // drop if it's just a space
                cite.outerHTML = innerHtml + '<a id="cite' + count + '" href="#citation' + count + '"><sup>' + count + '</sup></a>';

                references.innerHTML += '<li id="citation' + count + '"><a href="#cite' + count + '">' + count + '</a> ' + citation.citation + '</li>';
              }
            );
          }
          else {
            console.log('Status error: ' + request.status);
          }
        }
        else {
          console.log('Ignored readyState: ' + request.readyState);
        }
      }
    }

    request.open('POST', path);
    request.setRequestHeader('Content-type', 'application/json; charset=utf-8');

    request.send(JSON.stringify(citations));
  }
  
})