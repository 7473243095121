function handleNavbarToggler() {
  body.classList.toggle('expand');
}

function handleIconToggle(e) {
  const currentToggle = e.currentTarget;
  console.log(currentToggle);
  const toggleExpanded = currentToggle.getAttribute('aria-expanded');
  toggleExpanded === 'false'
    ? currentToggle.setAttribute('aria-expanded', 'true')
    : currentToggle.setAttribute('aria-expanded', 'false');
}

const body = document.querySelector('body');
const navbarToggler = document.querySelector('.navbar-toggler');
const iconToggles = document.querySelectorAll('.icon-toggle');

navbarToggler ? navbarToggler.addEventListener('click', handleNavbarToggler) : '';

iconToggles.forEach(function (iconToggle) {
  iconToggle.addEventListener('click', handleIconToggle);
});
