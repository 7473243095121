function handleMobileSearch() {
  setTimeout(() => {
    searchInput.focus();
  }, 250);
}

const mobileSearchTrigger = document.querySelector('#mobileSearch');
const searchInput = document.querySelector('#collapseMobileSearch input');

mobileSearchTrigger ? mobileSearchTrigger.addEventListener('click', handleMobileSearch) : '';
