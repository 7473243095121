import Swiper, { Navigation, Pagination, Keyboard } from 'swiper';
Swiper.use([Navigation, Pagination, Keyboard]);

// Set default Swiper options
const defaultSwiperOptions = {
  direction: 'horizontal',
  slidesPerView: 1,
  loop: true,
  centerInsufficientSlides: true,
  watchOverflow: true,
  watchSlidesProgress: true,
  watchSlidesVisibility: true,
  slideVisibleClass: 'swiper-slide-visible',
  effect: 'fade',
  resizeObserver: true,
  fadeEffect: {
    crossFade: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'custom',
    clickable: true,
    renderCustom: renderCustomPagination,
  },
  a11y: {
    prevSlideMessage: 'Previous slide',
    nextSlideMessage: 'Next slide',
    firstSlideMessage: 'First slide',
    lastSlideMessage: 'Last slide'
  },
  keyboard: {
    enabled: true,
    onlyInViewport: true
  }
};

// Set additional options
const additionalOptions = {
  autoHeight: true
}

// Render custom pagination
function renderCustomPagination(swiper, current, total) {
  let paginationHtml = '';

  paginationHtml += '<div class="swiper-pagination-bullets">';
  for (let i = 1; i <= total; i++) {
    paginationHtml += `<button type="button" class="swiper-pagination-bullet${i == current
      ? ' swiper-pagination-bullet-active'
      : ''}" aria-label="Slide ${i}"></button>`;
  }
  paginationHtml += '</div>';

  return paginationHtml;
}

function initializeSwiper(swiperContainer) {
  let options = {
    ...defaultSwiperOptions
  };

  return new Swiper(swiperContainer, options);
}

// Initialize Swiper instances
const swiperHero = initializeSwiper('.swiper-container.hero');
const swiperFeaturedArticles = initializeSwiper('.swiper-container.featured-articles');
const swiperVaccineReaction = new Swiper('.swiper-container.vaccine-reactions', defaultSwiperOptions);
